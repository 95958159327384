<template>
  <div class="page">
    <PageTitle :title="title"></PageTitle>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageTitle from "@/components/PageTitle";
export default {
  name: "SellerManageIndex",
  components: {
    PageTitle,
  },
  computed: {
    title() {
      let name = "推广人员管理";
      if (this.routes.length > 0)
        name = this.routes[this.routes.length - 1].name;
      return name;
    },
    ...mapState({
      routes: "route",
    }),
  },
  mounted() {},
  beforeDestroy() {
    this.$store.commit("changeRoute", null);
  },
  beforeRouteUpdate(to, from, next) {
    let path = to.path;
    const name = to.name;
    const chineseName = {
      SellerAdd: "新增推广人",
      SellerWithdraw: "提现",
      WithdrawList: "提现流水",
      SellerOrderList: "佣金订单",
    };
    const toPage = {
      link: path,
      name: chineseName[name],
    };
    this.$store.commit("changeRoute", toPage);
    next();
  },
};
</script>
<style scoped lang="scss">
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
